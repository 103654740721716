import {
  Colors,
} from '@bluecurrent/web-component-lib';

const BackgroundHandler = {
  gradientFilled: {
    backgroundColor: Colors.GRADIENT,
    changeContentColor: false,
  },
  gradient: {
    backgroundColor: 'linear-gradient(#E4F3FA 70%, #E4F3FA00 100%)',
    changeContentColor: false,
  },
  blue: {
    backgroundColor: Colors.BLUE,
    changeContentColor: true,
  },
  green: {
    backgroundColor: Colors.GREEN,
    changeContentColor: true,
  },
};

export default BackgroundHandler;
