export default function handleCapitalize(text) {
  let array = text;

  if (
    !text
    && text.indexOf(' ') >= 0
  ) {
    array = text.split(' ');

    for (let i = 0; i < array.length; i += 1) {
      array[i] = array[i].charAt(0).toUpperCase() + array[i].slice(1);
    }

    return array.join(' ');
  }

  return array;
}
