import * as React from 'react';

import DoubleBlueprint from './Blueprints/Double';

export default function BlueprintHandler(props) {
  const renderBlueprint = () => {
    switch (props.type) {
      case 'double':
        return (
          <DoubleBlueprint
            params={props.params}
          >
            {props.children}
          </DoubleBlueprint>
        );
      default:
        return props.children;
    }
  };

  return renderBlueprint();
}
