import * as React from 'react';

import {
  Breakpoint,

  useDimensions,

  Container,
} from '@bluecurrent/web-component-lib';

export default function Double(props) {
  const size = useDimensions();

  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [isMirrored, setMirrored] = React.useState(false);

  React.useEffect(() => {
    if (!isLoading) {
      setMirrored(
        (data[0]?.type === 'Form')
          ? false
          : props.params.mirror,
      );
    }
  }, [isLoading, data]);

  const priority = {
    Image: 1,
    Video: 2,
    TextBlock: 3,
    Quote: 4,
    Form: size.width < Breakpoint.sm ? 5 : undefined,
  };

  const array = props.params.components.map((i, index) => ({
    component: props.children[index],
    ...i,
  }));

  React.useEffect(() => {
    array.sort((a, b) => priority[a.type] - priority[b.type]);
    setData(array);
    setLoading(false);
  }, [size]);

  return (
    <div
      style={{
        marginTop: props.params.background ? 60 : 0,
        marginBottom: props.params.background ? 50 : 0,
      }}
    >
      <Container
        type="row"
        share={[50, 50]}
        breakpoint={Breakpoint.sm}
        invert={size.width >= Breakpoint.sm && isMirrored}
      >
        <div
          style={{
            paddingLeft: (size.width >= Breakpoint.sm && isMirrored) ? '2.5%' : 0,
            paddingRight: (size.width < Breakpoint.sm || isMirrored) ? 0 : '2.5%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {!isLoading && data[0].component}
        </div>
        <div
          style={{
            paddingRight: (size.width >= Breakpoint.sm && isMirrored) ? '2.5%' : 0,
            paddingLeft: (size.width < Breakpoint.sm || isMirrored) ? 0 : '2.5%',
            marginTop: size.width < Breakpoint.sm ? 20 : 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {!isLoading && data[1].component}
        </div>
      </Container>
    </div>
  );
}
