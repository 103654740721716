import * as React from 'react';

import handleModule from '../functions/handleModule';

export default function ComponentHandler(props) {
  const formatDataType = (value) => {
    let result = value.replace(/([a-z])([A-Z])/g, '$1_$2');
    result = result.replace(/([A-Z])([A-Z])/g, '$1_$2');

    return result.toLowerCase().toString();
  };

  if (handleModule(props.data.type) === undefined) return null;

  return React.cloneElement(
    handleModule(props.data.type).component,
    {
      params: props.params,
      data: props.data[formatDataType(props.data.type)],
    },
  );
}
