import * as React from 'react';
import Head from 'next/head';

import {
  Lang,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/Setup.config';

import handleCapitalize from '../../functions/handleCapitalize';

export default function HeadWrap(props) {
  const { _ } = Lang.useTranslation();

  const url = `https://www.bluecurrent.nl${props.slug !== undefined ? props.slug : ''}`;

  const title = () => {
    switch (props.type) {
      case 'installer':
        return `${Setup.APP_NAME} ${_('forInstallers', { ns: 'branding' }).toLowerCase()}`;
      case 'lease':
        return `${Setup.APP_NAME} ${_('forLease', { ns: 'branding' }).toLowerCase()}`;
      case 'zakelijke-laadpalen':
        return `${_('forBusinessLocations', { ns: 'branding' })} - ${Setup.APP_NAME}`;
      default:
        return `${(props.title !== '' && props.title !== undefined) ? `${handleCapitalize(props.title)} •` : ''} ${Setup.APP_NAME} - ${_('togetherTowardsSustainableFuture', { ns: 'branding' })}`;
    }
  };

  const description = () => {
    switch (props.type) {
      case 'zakelijke-laadpalen':
        return 'Ben je op zoek naar een betrouwbare zakelijke laadoplossing. Vraag dan nu een vrijblijvende offerte aan voor de zakelijke laadpalen van Blue Current.';
      default:
        return 'Innovative charge points for any situation where sustainable mobility plays a role.';
    }
  };

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#0098DB" media="(prefers-color-scheme: light)" />
      <meta name="theme-color" content="#0098DB" media="(prefers-color-scheme: dark)" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
      <meta name="robot" content="index, archive" />

      <title>{title()}</title>
      <meta name="description" content={description()} />

      <meta itemProp="name" content={title()} />
      <meta itemProp="description" content={description()} />
      <meta itemProp="image" content="https://cdn.sanity.io/images/pnajo2cq/production/6243a3a2ef3110c0e3b4ef2607dff94bda46c8da-1200x630.png?q=100" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title()} />
      <meta property="og:description" content={description()} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content="https://cdn.sanity.io/images/pnajo2cq/production/6243a3a2ef3110c0e3b4ef2607dff94bda46c8da-1200x630.png?q=100" />

      <meta property="og:locale" content="nl_NL" />
      <meta property="og:locale:alternate" content="en_GB" />
      <meta property="og:locale:alternate" content="en_US" />

      <meta name="google-site-verification" content="UzHykxoAtnGGU14v28UqWDbl_QV4aczZYtolbykIYgg" />
    </Head>
  );
}
