import * as React from 'react';
import { useRouter } from 'next/router';

import {
  Breakpoint,

  useDimensions,

  ContentContainer,
} from '@bluecurrent/web-component-lib';

import ContainerHandler from './ContainerHandler';
import BackgroundHandler from './BackgroundHandler';
import ComponentHandler from './ComponentHandler';
import BlueprintHandler from './BlueprintHandler';

export default function PageBuilder(props) {
  const { asPath } = useRouter();
  const size = useDimensions();

  const ref = React.useRef(null);

  const [width, setWidth] = React.useState('100%');

  const navHeight = 90;

  React.useEffect(() => {
    if (asPath.includes('#') && document.getElementById(asPath.split('#')[1]) !== null) {
      // Without delay there are cases where it doesn't scroll far enough
      setTimeout(() => {
        const element = document.getElementById(asPath.split('#')[1]);

        /*
         * Distance between top and element,
         * Current scrollHeight (often 0),
         * Pixel offset (based on navbar height + 10px)
         */
        const latitude = element.getBoundingClientRect().top + window.scrollY - navHeight;
        window.scrollTo({
          top: latitude,
          behavior: 'smooth',
        });
      }, 250);
    }
  }, []);

  const getWidth = () => {
    if (
      (props.data[0]?.layout !== undefined)
      && (ref.current.clientWidth !== null)
    ) {
      setWidth(ref.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getWidth();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getWidth);

    return () => window.removeEventListener('resize', getWidth);
  }, []);

  const spacingHandler = (index) => {
    if (index === 0) return 0;
    if (size.width < Breakpoint.sm) return 80;
    return 100;
  };

  return (
    (
      props.data[0]?.layout !== undefined
    ) && props.data[0].layout.map((section, index) => (
      <div
        /* eslint-disable-next-line no-underscore-dangle  */
        key={section._key}
        style={{
          marginTop: spacingHandler(index),
          marginBottom:
            (props.data[0].layout.length === index + 1)
              && (props.data[0].layout[0]?.Components[0]?.form?.type !== 'TypeForm')
              ? size.width < Breakpoint.sm
                ? 100
                : 150
              : 0,
        }}
      >
        <ContainerHandler
          type={section.Container}
          background={section.Background}
          params={{
            components: section.Components,
            advanced: section.Advanced,
          }}
        >
          <BackgroundHandler
            type={section.Background}
            container={section.Container}
            width={width}
            params={{
              components: section.Components,
              blueprint: section.Blueprint,
            }}
          >
            <BlueprintHandler
              type={section.Blueprint}
              params={{
                index,
                mirror: index % 2 !== 1,
                components: section.Components,
                background: section.Background,
              }}
            >
              {
                section.Components.map((object, objectIndex) => (
                  <React.Fragment
                    /* eslint-disable-next-line no-underscore-dangle  */
                    key={object._key}
                  >
                    <ComponentHandler
                      params={{
                        /* eslint-disable-next-line no-underscore-dangle  */
                        id: props.data[0]._id,
                        /* eslint-disable-next-line no-underscore-dangle  */
                        key: object._key,
                        index,
                        objectIndex,
                        layoutIndex: 0,
                        mirror: index % 2 !== 1,
                        blueprint: section.Blueprint,
                        background: section.Background,
                        container: section.Container,
                        length: props.data[0]?.layout.length,
                      }}
                      data={object}
                    />
                  </React.Fragment>
                ))
              }
            </BlueprintHandler>
          </BackgroundHandler>
        </ContainerHandler>
        {
          section.Background !== 'none' && (
            <ContentContainer>
              <div
                ref={ref}
                style={{
                  width: '100%',
                  height: 0,
                }}
              />
            </ContentContainer>
          )
        }
      </div>
    ))
  );
}
