import * as React from 'react';

import {
  MainContainer,
  LargeContainer,
  ContentContainer,
} from '@bluecurrent/web-component-lib';

export default function ContainerHandler(props) {
  const renderHandler = (children) => {
    switch (props.type) {
      case 'MainContainer':
        return <MainContainer>{children}</MainContainer>;
      case 'LargeContainer':
        return <LargeContainer>{children}</LargeContainer>;
      case 'ContentContainer':
        return <ContentContainer>{children}</ContentContainer>;
      default:
        return children;
    }
  };

  return (
    <section
      id={props.params?.advanced?.id}
    >
      {renderHandler(props.children)}
    </section>
  );
}
