import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Breakpoint,
  Colors,

  useDimensions,

  Confetti,
} from '@bluecurrent/web-component-lib';

import BackgroundConfig from '../config/BackgroundHandler.config';

export default function BackgroundHandler(props) {
  const size = useDimensions();

  const backgroundHandler = () => {
    switch (props.type) {
      case 'gradientFilled':
        return BackgroundConfig.gradientFilled.backgroundColor;
      case 'gradient':
        return BackgroundConfig.gradient.backgroundColor;
      case 'blue':
        return BackgroundConfig.blue.backgroundColor;
      case 'green':
        return BackgroundConfig.green.backgroundColor;
      default:
        return 'transparent';
    }
  };

  return props.container === 'LargeContainer' || props.container === 'MainContainer' ? (
    <motion.div
      style={{
        width: '100%',
        background: backgroundHandler(),
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderRadius: (size.width < Breakpoint.xxl && props.container === 'LargeContainer') ? 0 : 10,
        overflow: 'hidden',
        position: 'relative',
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      <div
        style={{
          width: props.width,
          height: 'auto',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {props.children}
      </div>
      {
        (
          props.params.blueprint === 'double'
          && props.params.components[0].type === 'Form'
        ) && (
          <Confetti
            type="DotsPath"
            height={1000}
            width={1000}
            color={Colors.WHITE}
            position="absolute"
            transform={`scaleX(-1) rotate(${size.width < Breakpoint.sm ? 0 : 20}deg)`}
            right={size.width < Breakpoint.sm ? '-50%' : '-35%'}
            top={-350}
            opacity={0.3}
            zIndex={0}
          />
        )
      }
    </motion.div>
  ) : props.children;
}
